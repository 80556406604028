<template>
  <div>
    <el-card>
      <div>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="mtName" label="模式名称" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="tenantName" label="公司名称" width="100" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="startTime" label="开始时间" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="状态" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.status | ziHuoDongStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="modelTypeName" label="活动类型" width="150" :show-overflow-tooltip="true"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="modelHiqClick(scope.row)">选题</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="modelHiqListClick(scope.row)">查看选题</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import uploadImg from '@/components/common/uploadImg/Index.vue'
import Cookies from 'js-cookie'
import zu1 from './zu1.vue'

const projectItem = JSON.parse(window.localStorage.getItem('PROJECT') || '{}')
const saveInfoParams = {
  tenantName: projectItem.tenantName,
  projectId: projectItem.id,
  type: 4,
  mtName: 'hiq113',
  description: 'newdict',
  imgBg: '',
  imgBanner: '',
  detailIntroduce: '1231',
  hiqNum: '',
  hiqDayNum: '',
  imgIcon: '',
  needRank: '',
  reachStandardDay: '',
  startTime: '',
  endTime: '',
}
export default {
  name: 'AdminDemoIndex',
  components: {
    pagination,
    uploadImg,
    zu1,
  },
  mixins: [commonMixin],
  data() {
    return {
      params: { projectId: '', pageNum: 1, pageSize: 10 },
      isDialog: false,
      projectItem: {},
      saveInfoParams: saveInfoParams,
      rules: {
        mtName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],

        startTime: [{ required: true, message: '请选择活动类型', trigger: 'blur' }],
        type: [{ required: true, message: '请选择活动类型', trigger: 'blur' }],
        imgBg: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        hiqNum: [{ required: true, message: '请输入题目数量', trigger: 'blur' }],
        hiqDayNum: [{ required: true, message: '请输入每日答题数量', trigger: 'blur' }],

        reachStandardDay: [{ required: true, message: '请输入达标天数', trigger: 'blur' }],
      },
      records: [],
      total: 0,
      templateList: [],
      timevalue: [],
      dialogType: 1,
      ziHuoDongDia: false, // 子活动dia
    }
  },
  watch: {
    params: {
      deep: true, //深度监听设置为 true
      handler: function () {
        this.getData()
      },
    },
  },
  computed: {
    modelType() {
      return function (type) {
        if (this.templateList.length == 0) {
          return ''
        }
        const items = this.templateList.find((item) => item.type == type)
        return items.modelTypeName || type
      }
    },
  },
  mounted() {
    const projectItem = this.$route.query

    console.log('ziHuoDongList2', projectItem)
    this.projectItem = projectItem
    this.params.projectId = projectItem.id
    this.saveInfoParams.projectId = projectItem.id
    this.saveInfoParams.tenantName = projectItem.tenantName
    this.getData()
  },
  methods: {
    async getData() {
      console.log('this.$API.model', this.$API, this.$API.model)
      const res = await this.$API.ziHuoDong.getZiHuoDongList(this.params)
      if (res.status !== 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = +total
    },
    // 选题click
    modelHiqClick(row) {
      window.localStorage.setItem('Model', JSON.stringify(row))
      console.log('row', row)
      this.$router.push(`/xuanTiManage/xuanTi?id=${row.id}&tenantName=${row.tenantName}`)
    },
    modelHiqListClick(row) {
      window.localStorage.setItem('Model', JSON.stringify(row))
      this.$router.push('/xuanTiManage/seeXuanTi?id=' + row.id)
    },

    // 重置from
    resetFrom(msg) {
      msg && this.$message.success(msg)
      this.saveInfoParams = saveInfoParams
      this.$refs.editFormRef.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

.el-cascader {
  width: 100%;
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
